import { isWechat, getQueryString } from './index';
import { getSignature } from '@/api/user';
import shareImage from '@/assets/images/logo.png';

let wx = (window as any)['wx'];

//定义需要隐藏的菜单
const hiddenMenu = [
  'menuItem:share:qq',
  'menuItem:share:weiboApp',
  'menuItem:favorite',
  'menuItem:share:facebook',
  'menuItem:share:QZone',
  'menuItem:editTag',
  'menuItem:delete',
  'menuItem:copyUrl',
  'menuItem:originPage',
  'menuItem:readMode',
  'menuItem:openWithQQBrowser',
  'menuItem:openWithSafari',
  'menuItem:share:email',
  'menuItem:share:brand',
];

/**
 * 分享
 */
export function weChatShare() {
  const share = {
    shareTitle: '保准牛云神盾开放平台',
    shareDes: '基于行业积累，沉淀数十万风控名单，致力于共建保险风控联盟，促进保险业良性发展。',
    shareImage: shareImage,
  };
  initWxShare(share, '/');
}

/**
 * 分享方法，如地址栏有变化需在需要自定义分享的页面调用一次
 * @param shareData 分享要素定义
 * @param shareUrl 分享跳转地址
 */
export function initWxShare(shareData: any, shareUrl: any) {
  //分享标题
  const shareTitle = shareData.shareTitle;
  //分享描述
  const shareDes = shareData.shareDes;
  //分享图片
  let shareImage = window.location.protocol + '//' + window.location.host + shareData.shareImage;
  shareUrl = encodeURI(
    window.location.protocol + '//' + window.location.host + shareUrl + '?v=' + Math.random(),
  );
  let param: any = {};
  //param["url"] = window.document.location.href.split('#')[0];
  //param['url'] = window.location.protocol + "//" + window.location.host+'/';
  param['url'] = window.document.location.href;
  const openid = getQueryString('openid');
  param['openId'] = openid || JSON.parse(localStorage.getItem('openId') as any);
  getSignature(param)
    .then((res: any) => {
      const data = res;
      if (data) {
        wx.config({
          debug: false,
          appId: data.appId,
          timestamp: data.timestamp,
          nonceStr: data.nonceStr,
          signature: data.signature,
          jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'hideMenuItems'],
        });
        wx.ready(function () {
          wx.checkJsApi({
            // 分享到朋友圈等接口
            jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'hideMenuItems'],
            success: function () {
              wx.onMenuShareTimeline({
                title: shareTitle,
                link: shareUrl,
                imgUrl: shareImage,
                success: function () {
                  console.log('success1');
                },
                cancel: function () {},
              });
              wx.onMenuShareAppMessage({
                title: shareTitle,
                desc: shareDes,
                link: shareUrl,
                imgUrl: shareImage,

                success: function () {
                  console.log('success');
                },
                cancel: function () {},
              });
              // wx.showOptionMenu();
              // wx.hideMenuItems({
              //   menuList: hiddenMenu,
              // });
            },
          });
        });
      } else {
        // Toast.info(data.message);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
