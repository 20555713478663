import { action, observable, computed } from 'mobx';
// public 公开 private 私有 protected 保护
class GlobalStore implements Page.globalStore {
  @observable
  userInfo: any = JSON.parse(localStorage.getItem('userInfo') as any);

  @observable
  currentUser: any = null;

  @observable
  openId: any = JSON.parse(localStorage.getItem('openId') as any);

  @observable
  accessToken: any = JSON.parse(localStorage.getItem('accessToken') as any);

  // 是否手动点击退出登录的
  @observable
  isLoginOut: boolean = false;

  // 是否绑定
  @observable
  isBind: boolean = false;

  @action.bound
  setIsLoginOut(isLoginOut: boolean) {
    this.isLoginOut = isLoginOut;
  }

  @action.bound
  setCurrentUser(currentUser: User.UserInfo) {
    this.currentUser = currentUser;
  }

  // 是否登录
  @computed get isLogin() {
    return !!this?.userInfo?.openid;
  }

  @action.bound // 登录action
  login(res: any, accessToken: string): void {
    this.userInfo = res.userInfo;
    this.openId = res.userInfo.openid;
    this.isBind = res.fillInformation;
    this.accessToken = accessToken;
    localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
    localStorage.setItem('accessToken', JSON.stringify(accessToken));
    localStorage.setItem('openId', JSON.stringify(this.openId));
  }

  @action.bound
  loginOut() {
    localStorage.removeItem('userInfo');
    this.userInfo = null;
    this.currentUser = null;
    this.openId = null;
  }
}
const globalStore = new GlobalStore();
export { GlobalStore };
export default globalStore;
