export const getQueryString = (name: string): string => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return '';
};

// 判断是否是微信环境
export const isWechat = () => !!navigator.userAgent.toLowerCase().match(/micromessenger/gi);
