// 微信授权地址
const wxLoginUrls: any = {
  dev: 'http://wxtest.bznins.com/open/oauth/1025?scope=1',
  test: 'http://wxtest.bznins.com/open/oauth/1025?scope=1',
  uat: 'https://wxuat.bznins.com/open/oauth/1025?scope=1',
  prod: 'https://wx.bznins.com/open/oauth/1025?scope=1',
};

// 服务地址
const serverUrls: any = {
  dev: 'https://sitgateway.dmp.baozhunniu.cn',
  test: 'https://sitgateway.dmp.baozhunniu.cn',
  uat: 'https://uatgateway.dmp.baozhunniu.cn',
  prod: 'https://gateway.dmp.baozhunniu.cn',
};

// 验证码服务地址

const smsUrls: any = {
  dev: 'https://sitbznpgi.bznins.com/',
  test: 'https://sitbznpgi.bznins.com/',
  uat: 'https://uatbznpgi.bznins.com/',
  prod: 'https://bznpgi.bznins.com/',
};

// 环境
const env = process.env.REACT_APP_ENV;

const wxLoginUrl = wxLoginUrls[`${env}`];
const serverUrl = serverUrls[`${env}`];
const smsUrl = smsUrls[`${env}`];

// 黑名单类型
const TypeMap: any = [
  { label: '企业', value: 'ENTERPRISE' },
  { label: '医疗机构', value: 'HOSPITAL' },
  { label: '地域', value: 'ADDRESS' },
  { label: '工种', value: 'WORK_TYPE' },
  { label: '失信被执行人', value: 'SHAREHOLDER' },
];

// 证件类型
const certMap: any = [
  { label: '组织机构代码证', value: 31 },
  { label: '工商登记证', value: 32 },
  { label: '税务登记证', value: 33 },
  { label: '营业执照', value: 34 },
  { label: '统一社会信用代码', value: 37 },
];

// 原因

const reasonMap: any = [
  { label: '经营风险', value: 'MANAGEMENT_RISK' },
  { label: '司法风险', value: 'JUDICIAL_RISK' },
  { label: '工商风险', value: 'BUSINESS_RISK' },
  { label: '监管风险', value: 'REGULATORY_RISK' },
  { label: '其他风险', value: 'OTHER_RISK' },
];

export { wxLoginUrl, serverUrl, smsUrl, env, TypeMap, certMap, reasonMap };
