import React, { PureComponent } from 'react';
// import { HomeNav } from '@/components/utils';

import type { TypeRoute } from '@/routers';

type TypeProps = {
  routes: TypeRoute[]; // 首页NAV 路由数组，从 router 传过来的
};
class HomeLayout extends PureComponent<TypeProps> {
  render() {
    const { routes } = this.props;
    console.log(1);

    return (
      <div>
        <div>{this.props.children}</div>
        {/* <HomeNav routes={routes}/> */}
      </div>
    );
  }
}

export default HomeLayout;
