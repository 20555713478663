import axios from 'axios';
import { Toast } from 'antd-mobile';
import store from '@/store';
import history from '@/utils/history';
import { serverUrl } from '@/constants';

// axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  function (config) {
    // const url: any = config.url;
    // config.url = /^http.*$/g.test(url) ? url : `${serverUrl}${url}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);
// let count = 1;
axios.interceptors.response.use(
  async function (response) {
    const { data } = response;
    if ((response.config as any).skipErr) {
      return Promise.resolve(data);
    }
    if (response.status >= 200 && response.status < 400) {
      if (data.code) {
        return data.data;
      } else {
        if (data.code === 9000107 || data.code === 1000105 || data.code === 9000108) {
          // 过期token 重新登录
          store.loginOut();
          history.push('/login', { from: history.location });
          return Promise.reject({ msg: '未登录或已过期，请重新登录' });
        }
        Toast.show({
          // icon: 'fail',
          content: data.message,
        });
        return Promise.reject({ ...data, msg: data.msg ?? '未知错误，请稍后重试' });
      }
    } else {
      Toast.show({
        icon: 'fail',
        content: '网络错误！',
      });
      return Promise.reject({
        code: response?.status,
        msg: response?.statusText,
        response,
      });
    }
  },
  function (error) {
    // Do something with response error
    return Promise.reject(
      error?.response?.data || {
        code: error.response?.status,
        msg: error.response?.statusText,
        error,
      },
    );
  },
);

export default axios;
