import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import HomeLayout from '@/pages/Index';
import { inject, observer } from 'mobx-react';

import type { ReactElement, ReactNode } from 'react';
import { getQueryString } from '@/utils';
import { wxLoginUrl } from '@/constants';
import { weChatShare } from '@/utils/wxShare';
import { userInfo } from '@/api/user';
import { Toast } from 'antd-mobile';

export type TypeRoute = {
  path: string;
  name?: string;
  component: Promise<ReactElement> | Promise<ReactNode> | ReactNode | any;
  auth?: boolean;
  lazy?: boolean; // 是否懒加载路由 默认是true
  exact?: boolean;
  children?: TypeRoute[];
  title?: string;
  navName?: string;
};

@inject('globalStore')
@observer
class MyRouter extends Component {
  private routes: TypeRoute[] = [
    {
      path: '/home',
      component: HomeLayout,
      auth: true,
      children: [
        {
          path: '/home/index',
          name: 'index',
          navName: '首页',
          title: '首页',
          auth: true,
          component: () => import(/* webpackChunkName: 'home' */ '@/pages/Index/Home'),
        },
      ],
    },
    {
      path: '/welcome',
      title: '首页',
      // auth: true,
      component: () => import(/* webpackChunkName: 'success' */ '@/pages/Welcome'),
    },
    {
      path: '/search',
      title: '搜索',
      auth: true,
      component: () => import(/* webpackChunkName: 'success' */ '@/pages/Search'),
    },
    {
      path: '/upload',
      title: '上传黑名单',
      auth: true,
      component: () => import(/* webpackChunkName: 'success' */ '@/pages/UploadPage'),
    },
    {
      path: '/api',
      title: '上传黑名单',
      exact: true,
      auth: true,
      component: () => import(/* webpackChunkName: 'success' */ '@/pages/ApiPage'),
    },
    {
      path: '/api/detail',
      title: 'API详情',
      auth: true,
      component: () => import(/* webpackChunkName: 'success' */ '@/pages/ApiPage/ApiDetail'),
    },
    {
      path: '/success',
      title: '成功',
      auth: true,
      component: () => import(/* webpackChunkName: 'success' */ '@/pages/OtherPage/SuccessPage'),
    },
  ];

  componentDidMount() {
    const { openId, accessToken } = (this.props as any).globalStore;
    const openid = getQueryString('openid') || openId;
    const access_token = getQueryString('access_token') || accessToken;
    if (openid && access_token) {
      this.getUserInfo(openid, access_token);
    } else {
      window.location.href = wxLoginUrl;
    }
  }

  getUserInfo = (openid: string, access_token: string) => {
    const { login } = (this.props as any).globalStore;
    Toast.show({
      icon: 'loading',
      content: '加载中…',
      duration: 0,
      maskClickable: false,
    });
    userInfo({ openId: openid, accessToken: access_token })
      .then((res: any) => {
        if (res.userInfo.openid) {
          login(res, access_token);
          weChatShare();
          Toast.clear();
        } else {
          window.location.href = wxLoginUrl;
        }
      })
      .catch(() => {
        Toast.clear();
      });
  };

  render() {
    const { isLogin } = (this.props as any).globalStore;
    console.log('isLogin', isLogin, wxLoginUrl);
    return (
      <Router>
        <Suspense fallback={<div></div>}>
          <Switch>
            <Redirect from="/" exact to="/welcome" />
            <Redirect from="/home" exact to="/home/index" />
            {this.routes.map((route: TypeRoute) => (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                render={(props) => {
                  const children = route.children ? (
                    <Switch>
                      {route.children.map((routeChild: TypeRoute) => (
                        <Route
                          key={routeChild.path}
                          path={routeChild.path}
                          render={(props) => {
                            const Component = lazy(routeChild.component);
                            // document.title = routeChild.title as string;
                            return <Component {...props} />;
                          }}
                          exact={routeChild.exact}
                        />
                      ))}
                      <Redirect to="/" />
                    </Switch>
                  ) : (
                    []
                  );
                  if (route.children) {
                    return (
                      <route.component {...props} children={children} routes={route.children} />
                    );
                  } else if (route.lazy === false) {
                    return <route.component {...props} />;
                  } else {
                    // 懒加载组件
                    const Component = lazy(route.component);
                    // document.title = route.title as string;
                    return <Component {...props} children={children} />;
                  }
                }}
              />
            ))}
          </Switch>
        </Suspense>
      </Router>
    );
  }
}

export default MyRouter;
