import axios from '@/utils/request';

// 获取用户信息
export const userInfo = (params: any) =>
  axios.get<any, User.UserInfo>('/open/platform/api/out/blacklist/service/getSnsUserInfo', {
    params,
  });

//绑定用户信息
export const userBind = (data: any) =>
  axios.post<any, User.UserInfo>('/open/platform/api/out/blacklist/service/fill/user', data);

// 微信配置
export const getSignature = (params: any) =>
  axios.get<any, Api.wechatSignature>('/open/platform/api/out/blacklist/service/getWxJsApiSign', {
    params,
  });
